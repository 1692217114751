/**
 * Copyright 2024
 * SPDX-License-Identifier: CC BY
 */

import { CardConfig } from '@oasisprotocol/rose-app-ui/discover'

export const CARDS_CONFIG: { featured: CardConfig[]; dApps: CardConfig[]; tooling: CardConfig[] } = {
  featured: [
    {
      title: 'NEBY',
      type: 'DEX',
      imgSrc: '/discover/Neby.png',
      externalLink: 'https://www.neby.exchange/',
    },
    {
      title: 'Accumulated Finance',
      type: 'Liquid staking',
      imgSrc: '/discover/AccumulatedFinance.png',
      externalLink: 'https://accumulated.finance/',
    },
    {
      title: 'Bit Protocol',
      type: 'CDP Stablecoin',
      imgSrc: '/discover/BitProtocol.png',
      externalLink: 'https://bitusd.finance/',
    },
  ],
  dApps: [
    {
      title: 'Thorn Protocol',
      type: 'StableSwap',
      imgSrc: '/discover/ThornProtocol.jpg',
      externalLink: 'https://thornprotocol.com/',
    },
    {
      title: 'Daosis',
      type: 'Launchpad & Token Minter',
      imgSrc: '/discover/Daosis.jpg',
      externalLink: 'https://daosis.io/',
    },
    {
      title: 'Rosy',
      type: 'Meme',
      imgSrc: '/discover/Rosy.jpg',
      externalLink: 'https://rosytoken.com/',
    },
    {
      title: '4P SuperdApp',
      type: 'Web3 Messenger',
      imgSrc: '/discover/4PSuperdApp.jpg',
      externalLink: 'https://the4thpillar.io/',
    },
    {
      title: "Rakesh's Village",
      type: 'Meme & Token Minter',
      imgSrc: '/discover/RakeshsVillage.jpg',
      externalLink: 'https://www.rakeshvillage.fun/',
    },
    {
      title: 'Predictoor',
      type: 'Predictions',
      imgSrc: '/discover/Predictoor.jpg',
      externalLink: 'https://www.predictoor.ai/',
    },
    {
      title: 'Pixel Realm',
      type: 'NFT Marketplace & Gaming Incubator',
      imgSrc: '/discover/PixelRealm.jpg',
      externalLink: 'https://app.pixelrealm.io/',
    },
    {
      title: 'Protocol Monster Labs',
      type: 'Gaming',
      imgSrc: '/discover/ProtocolMonsterLabs.jpg',
      externalLink: 'https://protocolmonsterlabs.com/',
    },
    {
      title: 'Oasis Safe',
      type: 'Multisig Wallet',
      imgSrc: '/discover/OasisSafe.svg',
      externalLink: 'https://safe.oasis.io/welcome',
    },
    {
      title: 'Midas',
      type: 'RWA Yield',
      imgSrc: '/discover/Midas.jpg',
      externalLink: 'https://midas.app/',
    },
    {
      title: 'Oasis Blockvote',
      type: 'Governance',
      imgSrc: '/discover/OasisBlockvote.svg',
      externalLink: 'https://vote.oasis.io/',
    },
  ],
  tooling: [
    {
      title: 'Empyreal / Simulacrum',
      type: 'SDK & AI Agents',
      imgSrc: '/discover/Empyreal.jpg',
      externalLink: 'https://empyrealsdk.com/',
    },
    {
      title: 'Router Bridge',
      type: 'Bridge',
      imgSrc: '/discover/Router.jpg',
      externalLink: 'https://app.routernitro.com/swap',
    },
    {
      title: 'DEX Screener',
      imgSrc: '/discover/DexScreener.jpg',
      externalLink: 'https://dexscreener.com/oasissapphire',
    },
    {
      title: 'DappRadar',
      imgSrc: '/discover/DappRadar.jpg',
      externalLink: 'https://dappradar.com/rankings/protocol/oasis-sapphire',
    },
    {
      title: 'DefiLlama',
      imgSrc: '/discover/DefiLlama.jpg',
      externalLink: 'https://defillama.com/chain/Oasis%20Sapphire',
    },
    {
      title: 'ROSE Wallet',
      type: 'Wallet',
      imgSrc: '/discover/RoseWallet.svg',
      externalLink: 'https://wallet.oasis.io/',
    },
    {
      title: 'illuminex',
      type: 'Privacy Wallet',
      imgSrc: '/discover/illumineX.jpg',
      externalLink: 'https://illuminex.xyz/',
    },
    {
      title: 'Oasis Explorer',
      imgSrc: '/discover/OasisExplorer.svg',
      externalLink: 'https://explorer.oasis.io/',
    },
    {
      title: 'Oasis Docs',
      imgSrc: '/discover/OasisDocs.svg',
      externalLink: 'https://docs.oasis.io/',
    },
    {
      title: 'Transak',
      imgSrc: '/discover/Transak.jpg',
      externalLink: 'https://global.transak.com/',
    },
    {
      title: 'CoinMarketCap',
      imgSrc: '/discover/CoinMarketCap.jpg',
      externalLink: 'https://coinmarketcap.com/currencies/oasis-network/',
    },
    {
      title: 'CoinGecko',
      imgSrc: '/discover/Coingecko.jpg',
      externalLink: 'https://www.coingecko.com/en/coins/oasis',
    },
    {
      title: 'Reclaim Protocol',
      type: 'SDK',
      imgSrc: '/discover/ReclaimProtocol.svg',
      externalLink: 'https://www.reclaimprotocol.org/',
    },
    {
      title: 'RA1L',
      type: 'DID',
      imgSrc: '/discover/Ra1l.jpg',
      externalLink: 'https://ra1l.com/',
    },
    {
      title: 'OWallet',
      type: 'Wallet',
      imgSrc: '/discover/OWallet.jpg',
      externalLink: 'https://owallet.io/',
    },
    {
      title: 'Safepal',
      type: 'Wallet',
      imgSrc: '/discover/SafePal.png',
      externalLink: 'https://safepal.com/en/',
    },
    {
      title: 'StealthEX',
      type: 'Exchange',
      imgSrc: '/discover/StealthEX.svg',
      externalLink: 'https://stealthex.io/?to=rose',
    },
  ],
}
