import { FC } from 'react'

export const LogoIconRound: FC = () => (
  <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40.5" cy="40.5" r="40.5" fill="#6665D8" />
    <rect
      x="9.89732"
      y="17.5848"
      width="61.4464"
      height="47.3304"
      rx="8.71875"
      fill="#6665D8"
      stroke="#6665D8"
      strokeWidth="0.830357"
    />
    <path
      d="M20.1506 52.809C19.3358 51.7284 18.5918 50.5593 17.9186 49.3016C17.2455 48.0261 16.7141 46.6799 16.3244 45.2627C15.9346 43.8456 15.7398 42.3753 15.7398 40.8519C15.7398 39.3107 15.9346 37.8404 16.3244 36.441C16.7141 35.0239 17.2455 33.6864 17.9186 32.4287C18.5918 31.1533 19.3358 29.9753 20.1506 28.8947H23.6049C22.7901 30.0284 22.0461 31.2419 21.3729 32.535C20.6998 33.8281 20.1684 35.1744 19.7786 36.5739C19.3889 37.9556 19.1941 39.3816 19.1941 40.8519C19.1941 42.3044 19.3889 43.7304 19.7786 45.1299C20.1684 46.5293 20.6998 47.8756 21.3729 49.1687C22.0461 50.4441 22.7901 51.6576 23.6049 52.809H20.1506ZM60.7317 52.809H57.2774C58.1099 51.6576 58.8539 50.4441 59.5094 49.1687C60.1825 47.8756 60.7139 46.5293 61.1037 45.1299C61.4934 43.7304 61.6882 42.3044 61.6882 40.8519C61.6882 39.3816 61.4934 37.9556 61.1037 36.5739C60.7139 35.1744 60.1825 33.8281 59.5094 32.535C58.8539 31.2419 58.1099 30.0284 57.2774 28.8947H60.7317C61.5642 29.9753 62.3082 31.1533 62.9637 32.4287C63.6368 33.6864 64.1682 35.0239 64.5579 36.441C64.9477 37.8404 65.1425 39.3107 65.1425 40.8519C65.1425 42.3753 64.9477 43.8456 64.5579 45.2627C64.1682 46.6799 63.6368 48.0261 62.9637 49.3016C62.3082 50.5593 61.5642 51.7284 60.7317 52.809Z"
      fill="white"
    />
    <path
      d="M35.5419 48.491L35.3294 46.0464V35.2053H38.8368V48.491H35.5419ZM26.5342 42.8047V35.2053H30.0416V42.0075L26.5342 42.8047ZM30.0416 42.0075C30.0416 42.8933 30.1391 43.593 30.3339 44.1067C30.5465 44.6027 30.8388 44.9658 31.2108 45.1961C31.6005 45.4087 32.0345 45.515 32.5128 45.515C33.4339 45.5327 34.1336 45.2581 34.6119 44.6913C35.0902 44.1067 35.3294 43.2653 35.3294 42.167H36.5251C36.5251 43.5841 36.3125 44.7887 35.8874 45.7807C35.4799 46.755 34.8954 47.5078 34.1336 48.0393C33.3896 48.553 32.5128 48.8098 31.5031 48.8098C29.9088 48.8098 28.6776 48.3227 27.8096 47.3484C26.9594 46.3564 26.5342 44.8418 26.5342 42.8047L30.0416 42.0075ZM41.8755 48.491V35.2053H45.1703L45.3829 37.6498V48.491H41.8755ZM50.6706 48.491V41.6887L54.1781 40.8915V48.491H50.6706ZM50.6706 41.6887C50.6706 40.7853 50.5643 40.0855 50.3518 39.5895C50.1569 39.0935 49.8735 38.7393 49.5015 38.5267C49.1295 38.2964 48.6955 38.1813 48.1995 38.1813C47.2961 38.1635 46.5963 38.4381 46.1003 39.005C45.6221 39.5718 45.3829 40.4133 45.3829 41.5293H44.2138C44.2138 40.1121 44.4175 38.9164 44.8249 37.9421C45.2501 36.9501 45.8346 36.1973 46.5786 35.6835C47.3226 35.1521 48.1995 34.8864 49.2092 34.8864C50.8212 34.8864 52.0523 35.3824 52.9026 36.3744C53.7706 37.3487 54.1958 38.8544 54.1781 40.8915L50.6706 41.6887Z"
      fill="white"
    />
  </svg>
)
