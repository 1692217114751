import { FC } from 'react'

export const WrapLogoIcon: FC = () => (
  <svg height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 770.7 215.19">
    <g>
      <path
        fill="white"
        d="M366.39,100.2c6.76-5.77,11.96-12.28,15.57-19.57s5.42-14.97,5.42-22.97c0-12.14-3.04-22.51-9.1-31.15-6.05-8.64-14.33-15.22-24.81-19.75-10.48-4.53-22.3-6.76-35.46-6.76h-72.55c-5.38,0-9.77,4.39-9.77,9.77v195.65c0,5.38,4.39,9.77,9.77,9.77h.81c5.38,0,9.77-4.39,9.77-9.77v-76.98c0-5.38,4.39-9.77,9.77-9.77h18.05c6.19,0,12.07,2.87,15.86,7.79l65.55,84.91c1.84,2.41,4.71,3.79,7.75,3.79h0c8.1,0,12.71-9.34,7.72-15.75l-55.85-72.24c-2.83-3.65-.81-9.06,3.75-9.87,4.07-.71,8.74-1.84,13.02-3.57,9.77-3.29,18.01-7.79,24.81-13.56l-.07.04ZM308.73,100.51h-41.94c-5.95,0-10.79-4.85-10.79-10.79V25.48c0-3.86,3.11-6.97,6.97-6.97h55c8.64,0,16.53,1.59,23.75,4.78,7.18,3.19,12.95,7.64,17.27,13.41,4.32,5.77,6.48,12.74,6.48,20.95,0,8.64-2.48,16.17-7.4,22.65-4.92,6.48-11.61,11.47-20.03,14.97-8.42,3.5-18.19,5.24-29.27,5.24h-.04Z"
      />
      <path
        fill="white"
        d="M147.1,152.29h0c-.63,6.64-10.18,7.02-11.33.44l-.08-.44L106.47,7.95C105.54,3.33,101.48,0,96.76,0h-5.07c-4.71,0-8.77,3.31-9.71,7.93l-29.51,144.36-.67,3.65c-1.18,6.49-10.59,6.15-11.31-.4l-.36-3.24L20.05,8.55C19.37,3.65,15.18,0,10.24,0h-.32C3.89,0-.74,5.33.1,11.3l27.51,195.92c.64,4.57,4.55,7.96,9.16,7.96h13.4c4.39,0,8.17-3.08,9.06-7.38l28.38-137.51.88-5.07c1.1-6.37,10.28-6.3,11.28.09l.78,4.98,27.49,137.45c.86,4.32,4.66,7.43,9.07,7.43h12.74c4.61,0,8.52-3.4,9.16-7.96L186.52,11.3C187.35,5.33,182.72,0,176.7,0h0c-4.95,0-9.14,3.65-9.82,8.56l-19.78,143.72Z"
      />
      <path
        fill="white"
        d="M475.85,13.11l-65.8,189.79c-2.08,6.01,2.38,12.28,8.74,12.28h2.02c3.93,0,7.43-2.49,8.73-6.2l18.35-52.54c.8-2.3,2.97-3.83,5.4-3.83h82.16c2.44,0,4.61,1.55,5.41,3.85l18.16,52.5c1.29,3.73,4.8,6.23,8.74,6.23h2c6.36,0,10.82-6.27,8.74-12.28L512.68,13.11C509.96,5.27,502.57,0,494.26,0h0c-8.3,0-15.7,5.26-18.42,13.11ZM463.74,134.1c-3.94,0-6.7-3.89-5.4-7.6l23.29-66.69,7.47-23.49c1.69-5.32,9.22-5.31,10.9.01l7.21,22.86,23.28,67.32c1.28,3.71-1.47,7.59-5.41,7.59h-61.35Z"
      />
      <path
        fill="white"
        d="M736.64,7.87C725.85,2.63,713.15,0,698.56,0h-70.56c-5.47,0-9.9,4.43-9.9,9.9v196.03c0,5.11,4.14,9.25,9.25,9.25h2.16c5.11,0,9.25-4.14,9.25-9.25v-71.04c0-3.16,2.56-5.72,5.72-5.72h49.46c15.21,0,28.61-2.72,40.23-8.17,11.61-5.44,20.6-13.05,26.97-22.81,6.37-9.76,9.56-21.12,9.56-34.06s-2.98-24.51-8.94-34.06c-5.96-9.55-14.34-16.95-25.12-22.19ZM733.4,97.88c-10.07,8.53-23.33,12.79-39.76,12.79h-49.15c-3.16,0-5.72-2.56-5.72-5.72V24.22c0-3.16,2.56-5.72,5.72-5.72h54.08c15,0,27.07,4.16,36.22,12.48,9.14,8.32,13.72,19.37,13.72,33.14s-5.04,25.23-15.11,33.76Z"
      />
    </g>
  </svg>
)
