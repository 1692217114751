import * as e from "@oasisprotocol/client";
import * as i from "@oasisprotocol/client-rt";
import * as m from "cborg";
import { bytesToHex as u, encodeAbiParameters as l } from "viem";
const g = /* @__PURE__ */ new Map([
  [
    23294,
    {
      subcallAddress: "0x0100000000000000000000000000000000000103",
      paratimeRuntimeId: "000000000000000000000000000000000000000000000000f80306c9858e7279",
      grpcUrl: "https://grpc.oasis.io"
    }
  ],
  [
    23295,
    {
      subcallAddress: "0x0100000000000000000000000000000000000103",
      paratimeRuntimeId: "000000000000000000000000000000000000000000000000a6d1e3ebf60dff6c",
      grpcUrl: "https://testnet.grpc.oasis.io"
    }
  ]
]), I = async (s) => await e.address.fromData(
  i.address.V0_SECP256K1ETH_CONTEXT_IDENTIFIER,
  i.address.V0_SECP256K1ETH_CONTEXT_VERSION,
  e.misc.fromHex(s.replace("0x", ""))
), d = (s) => {
  const t = Number(s);
  if (!g.has(t))
    throw new Error(
      `Invalid [chainId], supported chain ids are: ${Array.from(g.keys()).join(", ")}`
    );
  return g.get(t);
}, T = (s, t, n) => {
  const { subcallAddress: a } = d(s), r = [{ type: "string" }, { type: "bytes" }], c = [
    "consensus.Delegate",
    u(
      m.encode({
        amount: [e.quantity.fromBigInt(n), i.token.NATIVE_DENOMINATION],
        to: e.staking.addressFromBech32(t)
      })
    )
  ], o = l(r, c);
  return {
    to: a,
    data: o
  };
}, h = (s, t, n) => {
  const { subcallAddress: a } = d(s), r = [{ type: "string" }, { type: "bytes" }], c = [
    "consensus.Undelegate",
    u(
      m.encode({
        shares: e.quantity.fromBigInt(n),
        from: e.staking.addressFromBech32(t)
      })
    )
  ], o = l(r, c);
  return {
    to: a,
    data: o
  };
}, f = "consensus.Delegations", E = "consensus.Undelegations", w = async (s, t) => {
  const { paratimeRuntimeId: n, grpcUrl: a } = d(s), r = await I(t);
  return (await new i.wrapper.QueryWrapper(e.misc.fromHex(n), f).setArgs({ from: r }).query(new e.client.NodeInternal(a))).map(({ to: o, shares: p }) => ({
    to: e.staking.addressToBech32(o),
    shares: e.quantity.toBigInt(p)
  }));
}, A = async (s, t) => {
  const { paratimeRuntimeId: n, grpcUrl: a } = d(s), r = await I(t);
  return (await new i.wrapper.QueryWrapper(e.misc.fromHex(n), E).setArgs({ to: r }).query(new e.client.NodeInternal(a))).map(({ from: o, shares: p, epoch: y }) => ({
    from: e.staking.addressToBech32(o),
    shares: e.quantity.toBigInt(p),
    epoch: BigInt(y)
  }));
};
export {
  T as consensusDelegate,
  h as consensusUndelegate,
  w as getDelegations,
  A as getUndelegations
};
